<template>
  <v-card class="ma-4">
    <v-toolbar>
      <v-toolbar-title>Timeclock Usage Tracker</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col>
          <v-card>
            <v-list>
              <v-subheader>Terms</v-subheader>
              <v-list-item-group v-model="selectedTerm">
                <v-list-item v-for="{ term, label, start, end, usage } of terms" :key="term" :value="term">
                  <v-list-item-content>
                    <v-list-item-title>{{ label }}</v-list-item-title>
                    <v-list-item-subtitle>{{ start }} - {{ end }}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{ usage.reduce((prev, { count }) => prev + count, 0) }} Scans</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-list>
              <v-subheader>Timeclocks</v-subheader>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { onMounted, ref, watch } from '@vue/composition-api'

export default {
  setup (props, { root }) {
    const selectedTerm = ref('')
    const terms = ref([])
    const timeclocks = ref([])
    const selectedTimeclock = ref('')

    onMounted(async () => {
      const aggregate = [
        { $match: { createdAt: { $ne: null } } },
        { $group: {
          _id: { $dateToString: { format: '%Y-%m', date: '$createdAt' } },
          count: { $sum: 1 }
        } },
        { $sort: { _id: 1 } }
      ]
      const { data: usageData } = await root.$feathers.service('timeclock/punch').find({ query: { aggregate } })
      const { data } = await root.$feathers.service('system/term').find({ query: { start: { $lte: new Date() }, end: { $gte: new Date(usageData[0]._id + '-01') }, type: 'Traditional', $sort: { start: -1 }, $limit: 5, $select: ['term', 'label', 'start', 'end'] } })
      terms.value = data.map((row) => {
        const start = new Date(row.start)
        const end = new Date(row.end)
        row.usage = usageData.filter(({ _id }) => new Date(_id + '-01') < end && new Date(_id + '-30') > start)
        return row
      })
      if (selectedTerm.value === '') {
        selectedTerm.value = terms.value[0].term
      }
    })

    watch(selectedTerm, () => {
      console.log(selectedTerm.value)
    })

    return {
      selectedTerm,
      terms,
      timeclocks,
      selectedTimeclock
    }
  }
}
</script>
