var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-4"},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Timeclock Usage Tracker")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-list',[_c('v-subheader',[_vm._v("Terms")]),_c('v-list-item-group',{model:{value:(_vm.selectedTerm),callback:function ($$v) {_vm.selectedTerm=$$v},expression:"selectedTerm"}},_vm._l((_vm.terms),function(ref){
var term = ref.term;
var label = ref.label;
var start = ref.start;
var end = ref.end;
var usage = ref.usage;
return _c('v-list-item',{key:term,attrs:{"value":term}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(label))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(start)+" - "+_vm._s(end))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(usage.reduce(function (prev, ref) {
	var count = ref.count;

	return prev + count;
}, 0))+" Scans")])],1)],1)}),1)],1)],1)],1),_c('v-col',[_c('v-card',[_c('v-list',[_c('v-subheader',[_vm._v("Timeclocks")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }